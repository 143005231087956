import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Empty } from '@h3/antd-vue';
let NoEnableOrgPage = class NoEnableOrgPage extends Vue {
    constructor() {
        super(...arguments);
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    }
};
NoEnableOrgPage = __decorate([
    Component({
        name: 'NoEnableOrgPage',
    })
], NoEnableOrgPage);
export default NoEnableOrgPage;
